import { createSlice } from '@reduxjs/toolkit'
import { getPatients, getPatientsAnalytics } from './actions'

const initialState = {
  patients: {},
  analytics: [],
  loading: false
}

export const patient = createSlice({
  name: 'patient',
  initialState,
  extraReducers: (builder) => {
    builder
    .addCase(getPatients.fulfilled, (state, action) => {
      state.patients = action?.payload?.data ?? {}
    })
    .addCase(getPatientsAnalytics.fulfilled, (state, action) => {
      state.analytics = action?.payload?.data ?? []
    })
  }
})

//export const {} = patient.actions

export default patient.reducer