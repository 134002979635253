import { useDispatch } from "react-redux"
import { notify } from "store/modules/global"
import useHandleError from "./useHandleError"

export default function useTryCatch() {
    let dispatch = useDispatch()
    const { handleError } = useHandleError()

    const tryCatch = (callback) => {
        try {
            return callback()
        } catch {
            dispatch(notify({display: true, status: 'error', message: handleError({})}))
        }
    }

    return {tryCatch}
}