import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import { Button } from "components/Buttons"
import PermissionList from "./PermissionList";

export default function SelectPermissions({ onClick }) {
    const [modules, setModules] = useState([
        {
            module: 'Dashboard',
            action: []
        },
        {
            module: 'Admin Management',
            action: []
        },
        {
            module: 'Patient Management',
            action: []
        },
        {
            module: 'Provider Management',
            action: []
        },
        {
            module: 'Role Management',
            action: []
        },
        {
            module: 'Audit trail',
            action: []
        }
    ])

    const handleSelectedPermission = useCallback((selectedModule, permission) => {
        const updatedModules = modules.map((item) => {
            if (item.module === selectedModule) {
                return {
                    ...item,
                    module: item.module,
                    action: !item.action.includes(permission) ?
                        [...item.action, permission] : item.action.filter(item => item !== permission)
                }
            }
            return item
        })
        setModules(updatedModules)
    },[modules])

    const createRole = () => {
        if (onClick) onClick(modules)
    }

    return (
        <div className="w-full lg:w-[720px] bg-[#ffffff]">
            <p className="text-16 text-center text-neutral_black font-campton_m border-b border-neutral_stroke_2 py-[14.5px]">Select Permissions</p>
            <div className="px-[24px] pt-[16px] pb-[32px]">
                <p className="text-18 text-primary_shade_300 font-campton_m mb-[2px]">Patient Manager</p>
                <p className="text-14 text-neutral_body font-campton_r leading-[21px] mb-[32px]">Lorem ipsum dolor sit amet consectetur molesti conse conse...</p>
                <div className="h-[220px] sm:h-[260px] max-w-[730px] overflow-auto mb-[32px] border border-neutral_stroke_2">
                    {
                        
                        modules.map((item) =>
                            <PermissionList
                                key={item.module}
                                item={item}
                                selectPermission={handleSelectedPermission}
                            />
                        )
                    }
                </div>
                <div className="flex justify-end w-full">
                    {/* <div className="mr-[16px]">
                        <Button
                            name="Edit"
                            theme="transparent"
                            width="w-[145px]"
                            type="button"
                        // onClick={skip}
                        />
                    </div> */}
                    <div>
                        <Button
                            name="Create Role"
                            theme="blue"
                            width="w-[236px]"
                            type="button"
                            onClick={createRole}
                            //disabled={disabled || loading}
                            className="mb-[16px]"
                        //loading={loading}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

SelectPermissions.propTypes = {
    onClick: PropTypes.func
}