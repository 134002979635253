import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  notification: {
    display: false,
    status: '',
    message: ''
  },
  showDrawer: true
}

export const global = createSlice({
  name: 'global',
  initialState,
  reducers: {
    notify: (state, action) => {
      if (action?.payload.display === false) {
        state.notification.display = false;
        return
      }
  
      state.notification.display = true;
      state.notification.status = action?.payload?.status;
      state.notification.message = action?.payload.message;
    },
    toggleDrawer: (state) => {
      state.showDrawer = !state.showDrawer;
    }
  }
})

// Action creators are generated for each case reducer function
export const { notify, toggleDrawer } = global.actions

export default global.reducer