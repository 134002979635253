import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import { AnimatePresence, motion } from "framer-motion"
import { ReactComponent as CloseDrawer } from "assets/icons/close-drawer.svg"
import { ReactComponent as OpenDrawer } from "assets/icons/open-drawer.svg"
import { ReactComponent as Logo } from "assets/icons/logo-icon.svg"
import { ReactComponent as LogoName } from "assets/icons/logo-name.svg"
import { ReactComponent as Dashboard } from "assets/icons/dashboard.svg"
import { ReactComponent as Patient } from "assets/icons/patient.svg"
import { ReactComponent as Admin } from "assets/icons/admin.svg"
import { ReactComponent as Roles } from "assets/icons/roles.svg"
import { ReactComponent as Logout } from "assets/icons/logout.svg"
import { ReactComponent as Settings } from "assets/icons/settings.svg"
import { Link, useLocation } from "react-router-dom"
import { logout } from "store/modules/auth/actions"
import { useDispatch, useSelector } from "react-redux"
import { toggleDrawer } from "store/modules/global"

export default function Drawer() {
    const { showDrawer } = useSelector(state => state.global)
    const dispatch = useDispatch()

    const iconMargin = useMemo(() => {
        return showDrawer ? 'mr-[13px]' : ''
    },[showDrawer])

    return (
        <motion.aside
            initial={{ width: showDrawer ? '238px' : '70px' }}
            animate={{ width: showDrawer ? '238px' : '70px', transition: { duration: .5 } }}
            className={`hidden sm:block shrink-0 bg-blue px-[16px] py-[22px] rounded-[16px] relative mr-[24px]`}
        >
            <div
                data-testid="drawer-control"
                onClick={() => dispatch(toggleDrawer())}
                className="absolute right-[-20px] cursor-pointer"
            >
                {showDrawer ? <CloseDrawer /> : <OpenDrawer />}
            </div>
            <div className="flex flex-col justify-between h-full">
                <div>
                    <div className={`flex ${showDrawer ? '' : 'justify-center'} items-center pb-[21px] border-b border-b-[#ffffff20]`}>
                        <Logo className={showDrawer ? 'mr-[6.42px]' : ''} />
                        <LogoName className={showDrawer ? 'block' : 'hidden'} />
                    </div>
                    <div className="py-[32px] border-b border-b-[#ffffff20]">
                        <p className={`text-12 text-neutral_white mb-[16px] ${showDrawer ? 'block' : 'hidden'}`}>Main</p>
                        <List
                            name="Dashboard"
                            showDrawer={showDrawer}
                            path="dashboard"
                            url="/dashboard"
                            icon={<Dashboard className={iconMargin} />}
                        />
                        <List
                            name="Patient Management"
                            showDrawer={showDrawer}
                            path="patient"
                            url="/patient-management"
                            icon={<Patient className={iconMargin} />}
                        />
                    </div>
                    <div className="py-[32px]">
                        <p className={`text-12 text-neutral_white mb-[16px] ${showDrawer ? 'block' : 'hidden'}`}>Others</p>
                        <List
                            name="Admin Management"
                            showDrawer={showDrawer}
                            path="admin"
                            url="/admin-management"
                            icon={<Admin className={iconMargin} />}
                        />
                        <List
                            name="Roles & Permission"
                            showDrawer={showDrawer}
                            path="roles"
                            url="/roles-and-permissions"
                            icon={<Roles className={iconMargin} />}
                        />
                    </div>
                </div>
                <div>
                    <List
                        name="Settings"
                        showDrawer={showDrawer}
                        path="settings"
                        url="/settings"
                        icon={<Settings className={iconMargin} />}
                    />
                    <div
                        data-testid="logout"
                        onClick={() => dispatch(logout())}
                        className={`
                            flex ${showDrawer ? '' : 'justify-center'} items-center rounded-[100px] cursor-pointer py-[9px] px-[14px] hover:bg-[#304D78]
                        `}
                    >
                        <Logout className={iconMargin} />
                        <p className={`${showDrawer ? 'block' : 'hidden'} text-14 text-neutral_white font-campton_r`}>Logout</p>
                    </div>
                </div>
            </div>
        </motion.aside>
    )
}

const List = ({ showDrawer, name, path, url, icon }) => {
    const { pathname } = useLocation()
    const [showLabel, setShowLabel] = useState(false)
    const regex = new RegExp(path)
    const match = regex.exec(pathname)

    return (
        <Link to={url}
            className={`
                flex ${showDrawer ? '' : 'justify-center'} items-center rounded-[100px] cursor-pointer relative
                ${match ? 'bg-brand_primary font-campton_m' : 'hover:bg-[#304D78] font-campton_r'} py-[9px] px-[14px] mb-[16px]
            `}
            onMouseOver={() => setShowLabel(true)}
            onMouseOut={() => setShowLabel(false)}
        >

            {icon}
            <p className={`${showDrawer ? 'block' : 'hidden'} text-14 text-neutral_white shrink-0`}>{name}</p>
            <AnimatePresence>
                {
                    showLabel &&
                    <motion.div
                        initial={{ opacity: 0, x: "-10px" }}
                        animate={{ opacity: 1, x: 0, transition: { type: 'spring', duration: .7 } }}
                        exit={{ opacity: 0, transition: { duration: .1 } }}
                        className={showDrawer ? 'hidden' : 'absolute left-[30px] flex items-center z-20'}
                    >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="-1" y="10" width="15.5147" height="15.5147" rx="2.58579" transform="rotate(-45 -1 10)" fill="#143566" />
                        </svg>
                        <p className='text-14 text-neutral_white shrink-0 bg-blue px-[16px] py-[6px] whitespace-nowrap rounded-[4px]'>{name}</p>
                    </motion.div>
                }
            </AnimatePresence>
        </Link>
    )
}

List.propTypes = {
    showDrawer: PropTypes.bool,
    path: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
    icon: PropTypes.element
}