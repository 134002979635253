import React from "react"
import PropTypes from "prop-types"
import { motion } from "framer-motion";

export default function FormWrapper({ onSubmit, children, className, autoComplete }) {
    const submitForm = (e) => {
        e.preventDefault()
        onSubmit && onSubmit()
    }

    return (
        <motion.div
            animate={{ scale: 1, opacity: 1 }}
            transition={{delay: .2}}
            initial={{ scale: .9, opacity: 0 }}
            className="w-full"
        >
            <form
                autoComplete={autoComplete}
                data-testid="form"
                className={className}
                onSubmit={submitForm}
            >
                {children}
            </form>
        </motion.div>
    )
}

FormWrapper.propTypes = {
    onSubmit: PropTypes.func,
    children: PropTypes.any,
    className: PropTypes.string,
    autoComplete: PropTypes.string
}