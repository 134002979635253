/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import PropTypes from "prop-types"
import FormWrapper from "components/FormWrapper";
import { Button } from "components/Buttons";
import { Input } from "components/Inputs";
import { useNavigate } from "react-router-dom";
import FormTitle from "components/FormTitle";
import FormCaption from "components/FormCaption";
import useAuth from "hooks/fetch/useAuth";

export default function ResetPassword({ email }) {
    const navigate = useNavigate()
    const { create_password, loading } = useAuth()
    const [formData, setFormData] = useState({
        password: '',
        confirm_password: '',
    })

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const submit = () => {
        create_password({ ...formData, email }, () => navigate('/'))
    }

    return (
        <FormWrapper
            key={0}
            className="h-[60vh] sm:h-full"
            onSubmit={submit}
        >
            <div className="flex flex-col justify-between h-[70vh] sm:h-full">
                <div>
                    <FormTitle title="Reset Password" />
                    <FormCaption caption="Enter your new password." />
                    <div className="mb-[31.5px]">
                        <Input
                            label="Password"
                            type="password"
                            id="password"
                            name="password"
                            readOnly={loading}
                            value={formData?.password}
                            onChange={handleChange}
                            helperText="At least 8 character (must be alphanumeric)"
                        />
                    </div>
                    <div className="mb-[32px]">
                        <Input
                            label="Confirm Password"
                            type="password"
                            id="confirm_password"
                            name="confirm_password"
                            readOnly={loading}
                            value={formData?.confirm_password}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div>
                    <Button
                        name="Save"
                        theme="blue"
                        width="w-full"
                        disabled={!formData.password || !formData.confirm_password || loading}
                        className="mb-[16px]"
                        loading={loading}
                    />
                    <Button
                        name="Back to login"
                        theme="transparent"
                        width="w-full"
                        type="button"
                        onClick={() => navigate('/')}
                    />
                </div>
            </div>
        </FormWrapper>
    )
}

ResetPassword.propTypes = {
    email: PropTypes.string,
    loading: PropTypes.bool
}