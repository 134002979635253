import { unwrapResult } from "@reduxjs/toolkit"
import { useState } from "react"
// Actions
import {
    createPassword,
    forgotPassword,
    inviteAdmin,
    login,
    validatePasswordResetCode
} from "store/modules/auth/actions"
import useHandleError from "../useHandleError"
import useInputValidate from "../useInputValidate"
import { useDispatch } from "react-redux"
import { notify } from "store/modules/global"
import useMakeRequest from "hooks/useMakeRequest"
import useTryCatch from "hooks/useTryCatch"
import { extractPIN } from "utils/extractPin"
import { removeSpecificKeys } from "utils/removeSpecificKeys"
import useValidateFormData from "hooks/useValidateFormData"

export default function useAuth() {
    let dispatch = useDispatch()
    const { validateFormData } = useValidateFormData()
    const { validate } = useInputValidate()
    const { makeDispatch } = useMakeRequest()
    const [loading, setLoading] = useState(false)
    const { handleError } = useHandleError()
    const {tryCatch} = useTryCatch()

    const login_user = (formData) => {
        return tryCatch(async () => {
            setLoading(true)
            return dispatch(login({ payload: formData }))
                .then(unwrapResult)
                .then((res) => {
                    if (res?.code === 200) return res
                    if (!res) {
                        dispatch(notify({ display: true, status: 'error', message: handleError({ message: 'Internal Server Error' }) }))
                        return
                    }
                    dispatch(notify({ display: true, status: 'error', message: handleError({ status: res?.code, message: res?.message }) }))
                })
                .finally(() => setLoading(false))
        })
    }

    const forgot_password = async (formData, callback) => {
        tryCatch(async () => {
            // check if email is valid
            if (!validate({ name: "email", value: formData.email })) return
            setLoading(true)
            makeDispatch({ action: forgotPassword({ payload: formData }), callback })
                .finally(() => setLoading(false))
        })
    }

    const validate_password_reset_code = async (email, pin, callback) => {
        tryCatch(async () => {
            const result = extractPIN(pin)
            setLoading(true)
            return makeDispatch({
                action: validatePasswordResetCode({
                    payload: {
                        email,
                        resetCode: result
                    }
                }),
                callback
            })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const create_password = async (formData, callback) => {
        tryCatch(() => {
            if (formData.password !== formData.confirm_password) return
            const enhancedFormData = formData;
            removeSpecificKeys(enhancedFormData, ["confirm_password"])
            if (!validateFormData(formData)) return; // check if formData is valid
            setLoading(true)
            makeDispatch({
                action: createPassword({ payload: { ...enhancedFormData } }),
                callback
            })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const invite_admin = (formData, callback) => {
        tryCatch(async () => {
            if (!validateFormData(formData)) return // check if formData is valid
            setLoading(true)
            return makeDispatch({ action: inviteAdmin({ payload: formData }), alert: false, callback })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    return {
        invite_admin,
        login_user,
        forgot_password,
        create_password,
        validate_password_reset_code,
        loading
    }
}