import React, { Suspense, lazy } from "react";
import { Outlet, createBrowserRouter } from "react-router-dom";
import AppLayout from "layouts/AppLayout";
// Pages
import ForgotPassword from "pages/ForgotPassword";
import GetStarted from "pages/GetStarted";
import Login from "pages/Login";
import Onboarding from "pages/Onboarding";
// Lazy Loaded Pages
const PatientManagement = lazy(() => import("pages/PatientManagement"))
const PatientDetails = lazy(() => import("pages/PatientManagement/PatientDetails"))

const router = createBrowserRouter([
    {
        path: "/",
        errorElement: <div>Something went wrong</div>,
        element: (
            <AppLayout>
                <Outlet />
            </AppLayout>
        ),
        children: [
            {
                path: 'dashboard',
                element: <div>dashboard</div>,
            },
            {
                path: 'patient-management',
                element: <PatientManagement/>,
            },
            {
                path: 'patient-management/:id',
                element: <PatientDetails/>,
            },
            {
                path: 'admin-management',
                element: <div>admin management</div>,
            },
            {
                path: 'roles-and-permissions',
                element: <div>roles and permissions</div>,
            },
            {
                path: 'settings',
                element: <div>settings</div>,
            },
        ]
    },
    {
        path: "login",
        element: <Login />,
    },
    {
        path: "get-started",
        element: <GetStarted />,
    },
    {
        path: "forgot-password",
        element: <ForgotPassword />,
    },
    {
        path: "onboarding",
        element: <Suspense fallback={<div>Loading</div>}><Onboarding /></Suspense>,
    },
    {
        path: "*",
        element: <div>Page Not Found</div>,
    }
]);

export { router }