/* eslint-disable no-use-before-define */
import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types"
import FormWrapper from "components/FormWrapper";
import { Button } from "components/Buttons";
import { Input } from "components/Inputs";
import FormTitle from "components/FormTitle";
import FormCaption from "components/FormCaption";
import { useNavigate } from "react-router-dom";
import Dropdown from "components/Dropdown";
import { useSelector } from "react-redux";
import { renameObjectKeys } from "utils/renameObjectKeys";
import useAuth from "hooks/fetch/useAuth";

export default function InviteAdmin({ back }) {
    const navigate = useNavigate()
    const { invite_admin, loading } = useAuth()
    const { roles } = useSelector(state => state.settings)
    const [selectedRole, setSelectedRole] = useState('')
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: '',
        role: ''
    })
    
    const convertRoles = useMemo(() => {
        return renameObjectKeys(roles, 'name', 'name')
    },[roles])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const selectRole = useCallback((e) => {
        setSelectedRole(e.name)
        setFormData({ ...formData, role: e.value})
    }, [formData])

    const submit = () => {
        invite_admin(formData, () => navigate('/dashboard'))
    }

    return (
        <FormWrapper
            key={0}
            onSubmit={submit}
        >
            <>
                <div className="mx-auto mb-[24px]">
                    <div>
                        <div className="w-full sm:max-w-[255px] sm:mx-auto sm:text-center">
                            <div className="flex justify-between sm:justify-center">
                                <FormTitle title="Invite Admin" className="text-24" />
                                <Button
                                    name="Skip"
                                    theme="transparent"
                                    width="w-[54px]"
                                    size="small"
                                    type="button"
                                    className="sm:hidden"
                                    onClick={() => navigate('/dashboard')}
                                />
                            </div>
                            <FormCaption caption="Lorem ipsum dolor sit amet consectetur molesti" className="text-14" />
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-[32px] gap-x-[24px] mb-[24px] border-dashed border-t border-t-neutral_stroke_1 pt-[24px]">
                            <div>
                                <Input
                                    label="First Name"
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    readOnly={loading}
                                    value={formData?.firstName}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <Input
                                    label="Last Name"
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    readOnly={loading}
                                    value={formData?.lastName}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <Input
                                    label="Email Address"
                                    type="text"
                                    id="emailAddress"
                                    name="emailAddress"
                                    readOnly={loading}
                                    value={formData?.emailAddress}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <Input
                                    label="Phone Number"
                                    type="text"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    readOnly={loading}
                                    value={formData?.phoneNumber}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <Dropdown
                                    label="Role Name"
                                    type="select"
                                    id="role"
                                    name="role"
                                    position="static"
                                    loading={loading}
                                    options={convertRoles}
                                    readOnly={loading}
                                    selected={selectedRole}
                                    onSelect={selectRole}
                                />
                            </div>
                        </div>
                        <div className="flex justify-between items-center">
                            <div className="flex justify-between sm:justify-normal w-full">
                                <div>
                                    <Button
                                        name="Back"
                                        theme="light_orange"
                                        width="w-[112px]"
                                        type="button"
                                        className="mr-[24px]"
                                        onClick={back}
                                        disabled={loading}
                                    />
                                </div>
                                <div>
                                    <Button
                                        name="Save & Continue"
                                        theme="blue"
                                        width="w-[153px]"
                                        disabled={
                                            !formData.firstName || !formData.lastName || !formData.emailAddress ||
                                            !formData.phoneNumber || !formData.role || loading
                                        }
                                        loading={loading}
                                    />
                                </div>
                            </div>
                            <Button
                                name="Skip"
                                theme="transparent"
                                width="w-[54px]"
                                size="small"
                                type="button"
                                className="hidden sm:block"
                                disabled={loading}
                                onClick={() => navigate('/dashboard')}
                            />
                        </div>
                    </div>
                </div>
            </>
        </FormWrapper>
    )
}

InviteAdmin.propTypes = {
    back: PropTypes.func,
    loading: PropTypes.bool
}