import { unwrapResult } from "@reduxjs/toolkit"
import { notify } from "../store/modules/global"
import { useDispatch } from "react-redux"
import useHandleError from "./useHandleError"
import { removeSpecificKeys } from "utils/removeSpecificKeys"
import instance from "services/axios-instance"

export default function useMakeRequest() {
    let dispatch = useDispatch()
    const { handleError } = useHandleError()

    const makeDispatch = ({action, callback, alert = true, successMessage}) => {
        return dispatch(action)
        .then(unwrapResult)
        .then(res => {
            if ([200, 201].includes(res?.code)) {
                alert && dispatch(notify({display: true, status: 'success', message: successMessage || res?.message}))
                if (callback) callback()
            }
            if(res.message === 'Session expired' && res.code === 401) return res
            if (![200, 201].includes(res?.code)) {
                dispatch(notify({display: true, status: 'error', message: handleError({status: res?.code, message: res?.message})}))
            }
            if (!res?.code) dispatch(notify({display: true, status: 'error', message: handleError({message: 'Internal Server Error'})}))
            return res
        })
        .catch(error => error)
    }

    const makeAnonymousRequest = async ({ url, method, alert = true, payload, params }) => {
        try {
            const removeEmptyParams = removeSpecificKeys({ ...params })
            const body = method?.toLowerCase() !== 'delete' ? { ...payload } : { data: { ...payload } }
            const queryParams = params ? '?' + new URLSearchParams(removeEmptyParams).toString() : ''
            const response = await instance[method || 'get'](`${url}${queryParams}`, body)
            const data = response?.data || response?.response?.data

            if (data?.message === 'Token expired' && data?.code === 401) return data
            if ([200, 201].includes(data?.code)) {
                if (alert) dispatch(notify({ display: true, status: 'success', message: data?.message }))
            }
            if (![200, 201].includes(data?.code) || !data?.code) {
                dispatch(
                    notify({
                        display: true,
                        status: 'error',
                        message: handleError({ status: data?.code, message: data?.message || 'Internal Server Error' })
                    })
                )
            }
            return data
        } catch (error) {
            return error
        }
    }

    return { makeDispatch, makeAnonymousRequest }
}