import { customAsyncThunk, createCustomAsyncThunk } from 'utils/createThunk'
import { patient } from '../../../services/endpoints'

const getPatients = createCustomAsyncThunk('patient/getPatients', 'get', patient.get_patients)
const getPatient = customAsyncThunk('patient/getPatient', 'get', patient.get_patients)
const getPatientsAnalytics = customAsyncThunk('patient/getPatientsAnalytics', 'get', patient.get_patients_analytics)

export {
    getPatients,
    getPatient,
    getPatientsAnalytics
}