import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import PropTypes from "prop-types"
import { motion } from "framer-motion";
import Spinner from "../Spinner";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg"
import { ReactComponent as ArrowUp } from "assets/icons/arrow-up.svg"

export default function Dropdown({
    label, id, options, onSelect, type, loading, position, width,
    selected, children, readOnly, size, placeholder
}) {
    const [labelStyles, setLabelStyles] = useState(false)
    const [display, setDisplay] = useState(false)
    const [search, setSearch] = useState('')
    let ref = useRef()

    const listener = (e) => {
        if (!ref.current.contains(e.target)) setDisplay(false)
    }

    useEffect(() => {
        document.addEventListener('mousedown', listener);
        return () => document.removeEventListener('mousedown', listener);
    }, [])

    const handleChange = useCallback((e) => setSearch(e.target.value), [search])

    const searchOptions = useMemo(() => {
        return !search ? options : options?.filter((option) => option?.name?.toLowerCase().includes(search?.toLowerCase()))
    }, [options, search])

    return (
        <section
            ref={ref}
            className={`
            ${size === 'small' ? 'rounded-[100px] border-neutral_stroke' : ''} ${width}
            ${size !== 'small' && display ? 'border-brand_primary' : 'border-neutral_stroke'} relative border rounded-[8px]
            `}
        >
            <div
                className="relative"
                onClick={() => readOnly ? {} : setDisplay(!display)}
            >
                <input
                    id={id}
                    name={id}
                    type="text"
                    placeholder={placeholder}
                    label={label}
                    value={selected}
                    data-testid={`${id}-dropdown`}
                    aria-labelledby={id}
                    onChange={handleChange}
                    onBlur={() => setLabelStyles(false)}
                    readOnly={true}
                    autoComplete="off"
                    className={
                        `${size === 'small' ? 'h-[32px] rounded-[100px]' : 'h-[56px] rounded-[8px]'} px-[16px] text-neutral_black
                        text-16 w-full outline-0 font-dmsans_r cursor-pointer font-normal hide_tap border-0 border-neutral_stroke bg-neutral_white`
                    }
                />
                <motion.label
                    htmlFor={id}
                    animate={{
                        scale: .8,
                        top: !labelStyles && !selected ? "8px" : "-16px",
                        fontSize: !labelStyles && !selected ? "16px" : "14px",
                    }}
                    className="
                    absolute left-[4px] text-neutral_body mb-2 block text-14
                    font-campton_r bg-neutral_white px-1 pt-2 cursor-text"
                >
                    {label}
                </motion.label>
                <div className="h-full absolute top-0 right-0 flex items-center px-[18.5px] cursor-pointer hide_tap">
                    {display ? <ArrowUp /> : <ArrowDown />}
                </div>
            </div>
            {
                display &&
                <motion.div
                    animate={{ opacity: 1, y: '0px' }}
                    initial={{ opacity: 0, y: '-10px' }}
                    data-testid='dropdown-modal'
                    className={
                        `${size === 'small' ? 'top-[34px] absolute' : 'static top-[60px]'} w-full
                        z-20 rounded-[8px] bg-neutral_white drop-shadow-md lg:drop-shadow-md`
                    }
                >
                    <div onClick={() => setDisplay(false)}>
                        {children}
                        <div
                            className={`
                            ${size === 'small' ? 'py-0 px-0' : 'py-[8px] px-[16px]'} 
                            max-h-[216px] overflow-auto border-t border-t-neutral_stroke_2
                            `}
                        >
                            {
                                type === 'select' && options?.map((option) =>
                                    <div
                                        key={option?.id || option.value}
                                        data-testid={option?.name}
                                        onClick={() => {
                                            onSelect({ name: option?.name, value: option?.value })
                                        }}
                                        className={`
                                        ${size === 'small' ? 'py-[11px] px-[8px]' : 'py-[11px] px-[20px]'} flex items-center justify-between
                                        cursor-pointer hide_tap transition ease-in-out duration-500 hover:bg-[#F2F3F3]
                                        `}
                                    >
                                        <div className="flex items-center">
                                            <p className="text-14 font-campton_r capitalize">{option?.name}</p>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        {
                            type === 'search' && searchOptions?.map((option) =>
                                <div
                                    key={option?.id || option?.name}
                                    data-testid={option?.name}
                                    onClick={() => {
                                        onSelect(option?.name)
                                        setSearch(option?.name)
                                    }}
                                    className="flex items-center justify-between py-[11px] px-[20px] cursor-pointer hide_tap transition ease-in-out duration-500 
                                    hover:bg-purple_light border-b-[0.5px] border-purple_light"
                                >
                                    <div className="flex items-center">
                                        <p className="text-16 font-dmsans_r font-normal">{option?.name}</p>
                                    </div>
                                </div>
                            )
                        }
                        {loading && options?.length === 0 && <Spinner />}
                        {!loading && !options?.length && <p className="text-center font-campton_r">No data found</p>}
                    </div>
                </motion.div>
            }
        </section>
    )
}

Dropdown.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    children: PropTypes.any,
    id: PropTypes.string,
    type: PropTypes.string,
    onSelect: PropTypes.func,
    options: PropTypes.array,
    loading: PropTypes.bool,
    selected: PropTypes.any,
    readOnly: PropTypes.bool,
    size: PropTypes.string,
    width: PropTypes.string,
    position: PropTypes.string
}