/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import AuthLayout from "layouts/AuthLayout";
import FormWrapper from "components/FormWrapper";
import { Button } from "components/Buttons";
import { Input } from "components/Inputs";
import { Link, useNavigate } from "react-router-dom";
import FormTitle from "components/FormTitle";
import FormCaption from "components/FormCaption";
import useAuth from "hooks/fetch/useAuth";

export default function Login() {
    const navigate = useNavigate()
    const { login_user, loading } = useAuth()
    const [formData, setFormData] = useState({
        email: '', password: '',
    })

    useEffect(() => {
        document.title = 'Login - Gabbi Health'
    }, [])

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const onSubmit = () => {
        login_user(formData)
        .then((res) => {
            if (res?.code === 200) {
                if(res?.data?.admin?.role.toLowerCase() === 'superadmin') {
                    return navigate('/onboarding')
                }
                navigate('/dashboard')
            }
        })
    }

    return (
        <div>
            <AuthLayout
                left={true}
                right={
                    <FormWrapper
                        onSubmit={onSubmit}
                    >
                        <div className="flex flex-col justify-between h-[70vh] sm:h-full">
                            <div>
                                <FormTitle title="Log In"/>
                                <FormCaption caption="Enter your email & password to login."/>
                                <div className="mb-[32px]">
                                    <Input
                                        label="Email Address"
                                        type="text"
                                        id="email"
                                        name="email"
                                        readOnly={loading}
                                        value={formData?.email}
                                        onChange={handleChange}
                                        showError={false}
                                    />
                                </div>
                                <div className="mb-[16px]">
                                    <Input
                                        label="Password"
                                        type="password"
                                        id="password"
                                        name="password"
                                        readOnly={loading}
                                        value={formData?.password}
                                        onChange={handleChange}
                                        showError={false}
                                    />
                                </div>
                                <div className="flex justify-end mb-[32px] hide_tap">
                                    <Link to="/forgot-password" className="text-16 text-orange_300 cursor-pointer">Forgot Password?</Link>
                                </div>
                            </div>
                            <Button
                                name="Login"
                                theme="blue"
                                width="w-full"
                                disabled={!formData.email || !formData.password || loading}
                                loading={loading}
                            />
                        </div>
                    </FormWrapper>
                }
            />
        </div>
    )
}