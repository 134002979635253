import React from "react";
import PropTypes from 'prop-types';
import { ReactComponent as DarkLogo } from "../assets/svg/logo-dark.svg";
import { ReactComponent as AuthImage } from "../assets/images/auth-image.svg";
import RedirectIfToken from "HOC/RedirectIfToken";

export default function AuthLayout({ left, center, right }) {
    return (
        <RedirectIfToken>
            <div className="flex relative py-0 sm:py-[32px] px-0 sm:px-[32px] max-w-[1200px] mx-auto h-screen">
                {
                    left &&
                    <div className="hidden lg:block relative">
                        <AuthImage className="w-[534px] h-full" />
                    </div>
                }
                {center}
                {
                    right &&
                    <div className="w-full h-full block sm:flex flex-col justify-center overflow-x-hidden overflow-y-auto px-[24px] py-[16px]">
                        <div className="mx-auto">
                            <div className="border-b border-b-[#F2F3F3] py-[12px] w-full mb-[24px] block lg:hidden">
                                <DarkLogo />
                            </div>
                            <div className="w-full sm:w-[387px]">
                                {right}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </RedirectIfToken>
    )
}

AuthLayout.propTypes = {
    left: PropTypes.bool,
    center: PropTypes.element,
    right: PropTypes.element
};