import React from 'react';
import { router } from 'router';
import { RouterProvider } from 'react-router-dom';
import Notification from 'components/Notifications';

function App() {
  return (
    <div className="relative">
      <Notification />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
