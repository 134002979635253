import { useState } from "react"
import useMakeRequest from "hooks/useMakeRequest"
import useTryCatch from "hooks/useTryCatch"
import { createRoles, getRoles } from "store/modules/settings/actions"

export default function useSettings() {
    const { makeDispatch } = useMakeRequest()
    const [loading, setLoading] = useState(false)
    const { tryCatch } = useTryCatch()

    const get_roles = () => {
        tryCatch(async () => {
            setLoading(true)
            return makeDispatch({ action: getRoles(), alert: false })
                .then((res) => res)
                .finally(() => setLoading(false))
        })
    }

    const create_roles = (formData, callback) => {
        tryCatch(async () => {
            setLoading(true)
            return makeDispatch({
                action: createRoles({ payload: formData }),
                callback
            })
                .then((res) => {
                    if(res?.code === 201) get_roles()
                })
                .finally(() => setLoading(false))
        })
    }

    return {
        create_roles,
        get_roles,
        loading
    }
}