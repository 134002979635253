/* eslint-disable no-use-before-define */
import React, { useCallback, useEffect, useState } from "react";
import AuthLayout from "layouts/AuthLayout";
import { EnterOTPForm, ForgotPasswordForm, ResetPasswordForm } from "components/Forms";
import useMultiStep from "hooks/useMultiStep";
import Modal from "components/Modal";
import useAuth from "hooks/fetch/useAuth";
import Spinner from "components/Spinner";

export default function ForgotPassword() {
    const { forgot_password, validate_password_reset_code, loading } = useAuth()
    const [modalLoader, setModalLoader] = useState(false)
    const [formData, setFormData] = useState({
        email: ''
    })

    const animation = {
        initial: { scale: 0 },
        animate: { scale: 1, transition: { duration: .3 } }
    }

    useEffect(() => {
        document.title = 'Forgot Password - Gabbi Health'
    }, [])

    const setPin = useCallback((e) => {
        setFormData({ ...formData, resetCode: e })
    }, [formData])
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const receiveOTP = () => {
        forgot_password(formData, next)
    }
    const resendOTP = (callback) => {
        forgot_password({ email: formData.email }, callback)
    }
    const verifyOTP = (pin) => {
        setModalLoader(true)
        validate_password_reset_code(formData.email, pin, next)
            .finally(() => setModalLoader(false))
    }

    const { step, next } = useMultiStep([
        <ForgotPasswordForm
            key={0}
            receiveOTP={receiveOTP}
            formData={formData}
            handleChange={handleChange}
            loading={loading}
        />,
        <EnterOTPForm
            key={1}
            setPin={setPin}
            verifyOTP={verifyOTP}
            resendOTP={resendOTP}
            loading={loading}
            caption={
                <>Enter the 6-digit otp that has been sent to <span className="text-orange_300 font-campton_m">{formData.email}</span></>
            }
        />,
        <ResetPasswordForm
            key={2}
            email={formData.email}
        />
    ])

    return (
        <>
            <AuthLayout
                left={true}
                right={step}
            />
            {
                modalLoader &&
                    <Modal
                        animation={animation}
                    >
                        <div className="bg-[#112e38] w-[181px] h-[165px] rounded-[16px] p-[40px] flex flex-col justify-end">
                            <div className="h-full">
                                <Spinner/>
                            </div>
                            <p className="text-neutral_white text-14 font-campton_m">Verifying OTP...</p>
                        </div>
                    </Modal>
            }
        </>
    )
}