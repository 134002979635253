import React from "react"
import PropTypes from "prop-types"

export default function FormCaption({ caption, className }) {
    return (
        <p className={`${className || 'text-16'} text-neutral_body font-campton_r mb-[24px] sm:mb-[32px]`}>{caption}</p>
    )
}

FormCaption.propTypes = {
    caption: PropTypes.any,
    className: PropTypes.string
}