import React from "react";
import PropTypes from 'prop-types';

export default function Button({ name, disabled, onClick, children, theme, width, loading, type, className, size }) {
    return(
        <button 
            disabled={disabled || loading} 
            onClick={onClick}
            type={type || 'submit'}
            className={
                `${theme === 'transparent' ? 'bg-neutral_white border-neutral_stroke_2 text-neutral_body' : ''}
                ${theme === 'blue' && disabled ? 'bg-primary_tint_200 text-neutral_white' : ''}
                ${theme === 'blue' && !disabled ? 'bg-brand_primary hover:bg-primary_tint_400 text-neutral_white' : ''}
                ${theme === 'orange' && disabled ? 'bg-orange_100 text-orange_200' : ''}
                ${theme === 'orange' && !disabled ? 'bg-orange_400 hover:bg-orange_300 text-neutral_white' : ''}
                ${theme === 'light_orange' ? 'bg-orange_100 hover:bg-orange_300 hover:text-neutral_white text-orange_300' : ''}
                ${width} ${className} border flex justify-center items-center ${size === 'small' ? 'h-[32px] text-14' : 'h-[48px] text-16'} transition ease-in-out duration-500 font-campton_m capitalize rounded-[100px] hide_tap`
            }
        >
            {
                loading && theme !== 'transparent' ? 
                <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="24" height="24" viewBox="0 0 128 128">
                    <g>
                        <path d="M75.4 126.63a11.43 11.43 0 01-2.1-22.65 40.9 40.9 0 0030.5-30.6 11.4 11.4 0 1122.27 4.87h.02a63.77 63.77 0 01-47.8 48.05v-.02a11.38 11.38 0 01-2.93.37z" fill="#FFFFFF"/>
                        <animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1000ms" repeatCount="indefinite" />
                    </g>
                </svg> : name || children
            }
        </button>
    )
}

Button.propTypes = {
    name: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    children: PropTypes.element,
    theme: PropTypes.string,
    width: PropTypes.string,
    loading: PropTypes.bool,
    type: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.string
};