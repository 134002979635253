import React, { useEffect, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import usePIN from "hooks/usePIN"
import { Pin } from "../Inputs"
import FormWrapper from "../FormWrapper"
import { Button } from "../Buttons"
import { extractPIN } from "utils/extractPin"
import useResendOtp from "hooks/useResendOtp"
import FormTitle from "components/FormTitle"
import FormCaption from "components/FormCaption"

export default function EnterOTP({
    verifyOTP, buttonName, caption, title, loading, setPin, resendOTP
}) {
    const inputRef = useRef([]);
    const [pin, onPaste, onChange, onPress] = usePIN(6, inputRef)
    const { timeLeft, resetTimer } = useResendOtp()

    const disabled = useMemo(() => {
        return extractPIN(pin)?.length !== 6 ? true : false
    }, [pin])

    const resend = () => {
        resendOTP(resetTimer)
    }

    useEffect(() => {
        const result = extractPIN(pin)
        setPin(result)
    }, [pin])

    const submit = () => {
        if (verifyOTP) verifyOTP(pin)
    }

    return (
        <FormWrapper
            onSubmit={submit}
            autoComplete="off"
        >
            <div className="flex flex-col justify-between h-[70vh] sm:h-full">
                <div>
                    <FormTitle title={title || "Enter OTP"} />
                    <FormCaption caption={caption} />
                    <Pin
                        id='pin'
                        name='pin'
                        type="text"
                        pin={pin}
                        inputRef={inputRef}
                        onChange={onChange}
                        onKeyDown={onPress}
                        onPaste={onPaste}
                        readOnly={loading}
                        className="flex justify-between mb-[24px]"
                    />
                    <p className="text-14 text-center font-campton_r text-neutral_body mb-[20px]">
                        Didn’t receive code?
                        {
                            timeLeft === 0 ?
                                <button
                                    data-testid="resend"
                                    type="button"
                                    disabled={loading || timeLeft !== 0}
                                    className='text-brand_primary ml-[4px] font-campton_m text-12 cursor-pointer hide_tap'
                                    onClick={resend}
                                >
                                    Resend
                                </button> :
                                <span className="ml-[4px]">Resend in <span className="text-neutral_black font-campton_m">{timeLeft}s</span></span>
                        }
                    </p>
                </div>
                <Button
                    name={buttonName || 'Submit'}
                    theme="blue"
                    disabled={disabled || loading}
                    loading={loading}
                    width="w-full"
                />
            </div>
        </FormWrapper>
    )
}

EnterOTP.propTypes = {
    onSubmit: PropTypes.func,
    buttonName: PropTypes.string,
    title: PropTypes.string,
    caption: PropTypes.any,
    loading: PropTypes.bool,
    setPin: PropTypes.func,
    verifyOTP: PropTypes.func,
    resendOTP: PropTypes.func
}