import { useState } from "react"
import {
    PASSWORD_LENGTH, PIN_LENGTH, REGEX_EMAIL,
    REGEX_PASSWORD, REGEX_LETTER
} from "constants/index.js"

export default function useInputValidate() {
    const [error, setError] = useState('')

    const checkPassword = ({value}) => {
        if (value?.length < PASSWORD_LENGTH || !REGEX_PASSWORD.test(value)) {
            setError(`Password must be ${PASSWORD_LENGTH} or more characters and must be alphanumeric`)
            return false
        }

        setError('')
        return true
    }

    const checkEmail = ({value}) => {
        if (!REGEX_EMAIL.test(value)) {
            setError('Please enter a valid email')
            return false
        }

        setError('')
        return true
    }

    const checkName = ({value}) => {
        if (value?.length < 2) {
            setError("Name must be more than 1 character")
            return false
        }
        if (!REGEX_LETTER.test(value)) {
            setError("Name must contain only alphabets and cannot have spaces")
            return false
        }
        setError('')
        return true
    }

    const checkConfirmPassword = ({ name, value }) => {
        if (value?.length === 0) return false
        if (document.getElementById(name)?.value === document.getElementById('password')?.value) {
            setError('')
            return true
        }

        setError('Passwords do not match!')
        return false
    }

    const checkPin = (value) => {
        if (value?.length !== PIN_LENGTH) return false
        setError('')
        return true
    }

    const validate = ({ name, value }) => {
        switch(name) {
            case 'password':
                return checkPassword({value})
            case 'firstName':
            case 'lastName':
                return checkName({name, value})
            case 'emailAddress':
            case 'email': 
                return checkEmail({name, value})
            case 'confirmPassword':
            case 'confirm_password':
                return checkConfirmPassword({name, value})
            case 'pin':
                return checkPin(value)
            default:
                setError('')
                return true
        }
    }

    return { 
        error, 
        setError, 
        validate, 
        checkPin 
    }
}