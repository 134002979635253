import { useEffect, useState } from "react"

export default function usePIN(numberOfPIN, ref) {
    const [pin, setPIN] = useState([])

    // Handle Pin
    useEffect(() => {
        let results = [];
        for (let i = 1; i <= numberOfPIN; i++) {
            results.push({
                key: i,
                value: '',
                isFocus: i === 1 ? true : false,
            })
        }
        
        setPIN(results)
    },[])

    //Handler Function
    const onPress = (e, currentInput) => {
        if (currentInput.key > 1 && e.key === 'ArrowLeft') ref.current[currentInput.key - 1].focus();
        if (currentInput.key < 6 && e.key === 'ArrowRight') ref.current[currentInput.key + 1].focus();
    };

    const onPaste = (e) => {
        e.stopPropagation();
        e.preventDefault();
    
        const clipboardData = e.clipboardData;
        const pastedData = clipboardData?.getData('Text');
        const breakCopyCodeToArray = pastedData?.split('');
    
        pin.forEach((x, index) => {
          x.value = breakCopyCodeToArray[index];
          return x;
        });
        setPIN([...pin]);
        ref.current[numberOfPIN?.length]?.focus();
    };

    const onChange = (e, currentInput) => {
        if (isNaN(e.target.value)) return
        if (e.nativeEvent.inputType === "insertText") {
            pin[currentInput.key - 1].value = e.target.value;
            setPIN([...pin]); //Update the value of the current input
        
            currentInput.key < numberOfPIN && ref.current[currentInput.key + 1].focus(); //Update the focus of the next input
        }

        if (currentInput.key > 0 && e.nativeEvent.inputType === "deleteContentBackward") {
            pin[currentInput.key - 1].value = '';
            setPIN([...pin]); //Update the value of the current input
        
            currentInput.key === 1 && ref.current[currentInput.key].focus(); //Update the focus of the next input
            currentInput.key !== 1 && ref.current[currentInput.key - 1].focus(); //Update the focus of the next input
        }
    };

    return [
        pin,
        onPaste,
        onChange,
        onPress,
        setPIN
    ]
}