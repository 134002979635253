import useInputValidate from "./useInputValidate"

export default function useValidateFormData() {
    const { validate } = useInputValidate()

    const validateFormData = (formData) => {
        let output = []
        Object.entries(formData).forEach((entry) => {
            const [key, value] = entry;
            const inputStatus = validate({ name: key, value })
            output.push(inputStatus)
        })

        return !output.includes(false)
    }

    return { validateFormData }
}