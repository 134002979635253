import { createSlice } from '@reduxjs/toolkit'
import { login } from './actions'

const initialState = {
  admin: {},
  authToken: '',
  refreshToken: '',
  authTokenExpiry: '',
  isLoggedIn: false,
  loading: false
}

const setUser = (state, action) => {
  if(action?.payload?.data) {
    state.admin = action?.payload?.data?.admin;
    state.authToken = action?.payload?.data?.authToken;
    state.refreshToken = action?.payload?.data?.refreshToken;
    state.authTokenExpiry = action?.payload?.data?.authTokenExpiry;
    state.isLoggedIn = action?.payload?.status === 'success' ? true : false;
  }
}

export const auth = createSlice({
  name: 'auth',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      setUser(state, action)
    })
  }
})

//export const {} = auth.actions

export default auth.reducer