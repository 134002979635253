import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from "framer-motion";

export default function TextArea({ id, name, placeholder, label, value, onChange, readOnly, rows, maxLength }) {
    const [labelStyles, setLabelStyles] = useState(false)

    return (
        <>
            <div className="relative mb-[3.5px]">
                <textarea
                    id={`textarea${name}`}
                    name={name}
                    rows={rows}
                    maxLength={maxLength}
                    onChange={onChange}
                    placeholder={placeholder}
                    data-testid={`test-${id}`}
                    onFocus={() => setLabelStyles(true)}
                    onBlur={() => setLabelStyles(false)}
                    aria-labelledby={id}
                    readOnly={readOnly}
                    value={value}
                    className={
                        `border-neutral_stroke px-[16px] pt-[32px] text-neutral_black
                        text-16 w-full outline-0 border font-dmsans_r font-normal hide_tap
                        rounded-[8px] focus:border-brand_primary focus:border bg-neutral_white`
                    }
                ></textarea>
                <motion.label
                    htmlFor={id}
                    animate={{
                        scale: .8,
                        top: !labelStyles && !value ? "8px" : "-16px",
                        fontSize: !labelStyles && !value ? "16px" : "14px",
                    }}
                    className="
                    absolute left-[4px] text-neutral_body mb-2 block text-14
                    font-campton_r bg-neutral_white px-1 pt-2 cursor-text"
                >
                    {label}
                </motion.label>
            </div>
        </>
    );
}

TextArea.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    rows: PropTypes.number,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    type: PropTypes.string,
    readOnly: PropTypes.bool,
    styles: PropTypes.string,
    maxLength: PropTypes.string
};
