import { createAsyncThunk } from '@reduxjs/toolkit'
import {customAsyncThunk} from 'utils/createThunk'
import { auth } from '../../../services/endpoints'

const login = customAsyncThunk('auth/login', 'post', auth.login)
const forgotPassword = customAsyncThunk('auth/forgotPassword', 'post', auth.forgot_password)
const createPassword = customAsyncThunk('auth/createPassword', 'patch', auth.create_password)
const validatePasswordResetCode = customAsyncThunk('auth/validatePasswordResetCode', 'post', auth.validate_password_reset_code)
const inviteAdmin = customAsyncThunk('auth/inviteAdmin', 'post', auth.invite_admin)
const logout = createAsyncThunk('auth/logout', async (payload, thunkAPI) => {
    thunkAPI.dispatch({ type: 'logout'})
})

export {
    login,
    inviteAdmin,
    forgotPassword,
    createPassword,
    validatePasswordResetCode,
    logout
}