/* eslint-disable no-use-before-define */
import React, { useMemo } from "react";
import PropTypes from "prop-types"
import FormWrapper from "components/FormWrapper";
import { Button } from "components/Buttons";
import { Input } from "components/Inputs";
import { useNavigate } from "react-router-dom";
import FormTitle from "components/FormTitle";
import FormCaption from "components/FormCaption";
import useValidateFormData from "hooks/useValidateFormData";

export default function ForgotPassword({ receiveOTP, formData, handleChange, loading }) {
    const navigate = useNavigate()
    const { validateFormData } = useValidateFormData()

    const disabled = useMemo(() => {
        return !validateFormData({email: formData.email})
    },[formData])

    return (
        <FormWrapper
            key={0}
            className="h-[60vh] sm:h-full"
            onSubmit={receiveOTP}
        >
            <div className="flex flex-col justify-between h-[70vh] sm:h-full">
                <div>
                    <FormTitle title="Forgot Password" />
                    <FormCaption caption="Enter your email address to reset password." />
                    <div className="mb-[32px]">
                        <Input
                            label="Email Address"
                            type="text"
                            id="email"
                            name="email"
                            readOnly={loading}
                            value={formData?.email}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div>
                    <Button
                        name="Receive OTP"
                        theme="blue"
                        width="w-full"
                        disabled={disabled || loading}
                        className="mb-[16px]"
                        loading={loading}
                    />
                    <Button
                        name="Back to login"
                        theme="transparent"
                        width="w-full"
                        type="button"
                        onClick={() => navigate('/')}
                    />
                </div>
            </div>
        </FormWrapper>
    )
}

ForgotPassword.propTypes = {
    formData: PropTypes.object,
    receiveOTP: PropTypes.func,
    handleChange: PropTypes.func,
    loading: PropTypes.bool
}