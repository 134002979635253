import React, { useMemo, useState } from "react"
import PropTypes from 'prop-types';
import useInputValidate from "../../hooks/useInputValidate";
import { motion } from "framer-motion";
// Icons
import { ReactComponent as EyeClosed } from 'assets/icons/eye-closed.svg';
import { ReactComponent as EyeOpen } from 'assets/icons/eye-open.svg';

export default function Input({
    id, name, placeholder, value, label, onChange, type, readOnly, helperText,
    maxLength, defaultValue, max, pattern, inputMode, showError, loading
}) {
    const [passwordView, setPasswordView] = useState(false)
    const [labelStyles, setLabelStyles] = useState(false)
    const { error, validate, setError } = useInputValidate(showError)

    const inputError = useMemo(() => {
        return !(showError === false || !error)
    },[error])

    return (
        <>
            <div className="relative mb-[3.5px]">
                <input
                    id={id}
                    name={name}
                    type={type === 'password' && (passwordView ? 'text' : 'password') || type}
                    label={label}
                    placeholder={placeholder}
                    value={value}
                    defaultValue={defaultValue}
                    maxLength={maxLength}
                    inputMode={inputMode}
                    max={max}
                    pattern={pattern}
                    data-testid={`test-${id}`}
                    aria-labelledby={id}
                    onChange={onChange}
                    readOnly={readOnly || loading}
                    autoComplete="off"
                    onFocus={() => {
                        setLabelStyles(true)
                    }}
                    onBlur={() => {
                        setLabelStyles(false)
                        validate({ name, value })
                    }}
                    onKeyDown={() => setError('')}
                    className={
                        `${inputError ? 'border-error' : 'border-neutral_stroke'} 
                        h-[56px] px-[16px] text-neutral_black cursor-text
                        text-16 w-full outline-0 border font-dmsans_r font-normal hide_tap
                        rounded-[8px] focus:border-brand_primary focus:border bg-neutral_white`
                    }
                />
                <motion.label
                    htmlFor={id}
                    animate={{
                        scale: .8,
                        top: !labelStyles && !value ? "8px" : "-16px",
                        fontSize: !labelStyles && !value ? "16px" : "14px",
                    }}
                    className="
                    absolute left-[4px] text-neutral_body mb-2 block text-14
                    font-campton_r bg-neutral_white px-1 pt-2 cursor-text"
                >
                    {label}
                </motion.label>
                {
                    type === 'password' &&
                    <div
                        onClick={() => setPasswordView(!passwordView)}
                        data-testid={!passwordView ? 'show' : 'hide'}
                        className="flex items-center absolute top-0 right-[13.48px] cursor-pointer hide_tap h-full"
                    >
                        {!passwordView ? <EyeClosed /> : <EyeOpen />}
                    </div>
                }
            </div>
            <p className={`font-dmsans_r ${inputError ? 'text-error' : 'text-neutral_body'} text-14`}>
                {inputError && error || helperText}
            </p>
        </>
    )
}

Input.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    type: PropTypes.string,
    readOnly: PropTypes.bool,
    maxLength: PropTypes.number,
    max: PropTypes.string,
    pattern: PropTypes.string,
    inputMode: PropTypes.string,
    showError: PropTypes.bool,
    helperText: PropTypes.string,
    loading: PropTypes.bool
};