/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import AuthLayout from "layouts/AuthLayout";
import FormWrapper from "components/FormWrapper";
import { Button } from "components/Buttons";
import { Input } from "components/Inputs";
import FormTitle from "components/FormTitle";
import FormCaption from "components/FormCaption";
import { useNavigate, useSearchParams } from "react-router-dom";
import useAuth from "hooks/fetch/useAuth";

export default function GetStarted() {
    const navigate = useNavigate()
    const { create_password, loading } = useAuth()
    const [searchParams] = useSearchParams();
    const email = searchParams?.get('email');
    const [formData, setFormData] = useState({
        password: '',
        confirm_password: '',
    })

    useEffect(() => {
        document.title = 'Get Started - Gabbi Health'
    }, [])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const submit = () => {
        create_password({ ...formData, email }, () => navigate('/login'))
    }

    return (
        <div>
            <AuthLayout
                left={true}
                right={
                    <FormWrapper
                        autoComplete="off"
                        onSubmit={submit}
                    >
                        <div className="flex flex-col justify-between h-[70vh] sm:h-full">
                            <div>
                                <FormTitle title="Welcome to GabbiHealth" />
                                <FormCaption
                                    caption={
                                    <>Create a password for <span className="text-orange_300 font-campton_m">{email}</span></>
                                    }
                                />
                                <div className="mb-[31.5px]">
                                    <Input
                                        label="Password"
                                        type="password"
                                        id="password"
                                        name="password"
                                        readOnly={loading}
                                        value={formData?.password}
                                        onChange={handleChange}
                                        helperText="At least 8 character (must be alphanumeric)"
                                    />
                                </div>
                                <div className="mb-[32px]">
                                    <Input
                                        label="Confirm Password"
                                        type="password"
                                        id="confirm_password"
                                        name="confirm_password"
                                        readOnly={loading}
                                        value={formData?.confirm_password}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <Button
                                name="Save"
                                theme="blue"
                                width="w-full"
                                disabled={(formData.password !== formData.confirm_password) || !formData.password || !formData.confirm_password}
                                loading={loading}
                            />
                        </div>
                    </FormWrapper>
                }
            />
        </div>
    )
}