import React from "react"
import PropTypes from "prop-types"

export default function FormTitle({ title, className }) {
    return (
        <h5 className={`${className || 'text-24 sm:text-32'} font-campton_m text-neutral_black`}>{title}</h5>
    )
}

FormTitle.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string
}