import React, { useState } from "react"
import PropTypes from "prop-types"
import { motion, AnimatePresence } from "framer-motion";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg"
import { ReactComponent as ArrowUp } from "assets/icons/arrow-up.svg"
import { ReactComponent as CheckboxUnselected } from "assets/icons/check-box-unselected.svg"
import { ReactComponent as CheckboxSelected } from "assets/icons/check-box-selected.svg"
import { ReactComponent as CheckboxTag } from "assets/icons/check-box-tag.svg"

export default function PermissionList({ item, selectPermission }) {
    const [showList, setShowList] = useState(false)
    const actions = ['Create', 'Read', 'Update', 'Delete']

    return (
        <div className="text-14 font-campton_r text-neutral_black">
            <div className="border-b border-b-neutral_stroke_2">
                <div
                    data-testid={item.module.toLowerCase()}
                    className="flex items-center justify-between bg-[#FAFAFA] py-[14px] px-[16px] cursor-pointer hide_tap"
                    onClick={() => setShowList(!showList)}
                >
                    <div className="flex items-center">
                        {
                            item?.action?.length ?
                            <CheckboxTag className="mr-[8px]" /> : <CheckboxUnselected className="mr-[8px]" />
                        }
                        <p>{item.module}</p>
                    </div>
                    {showList ? <ArrowUp /> : <ArrowDown />}
                </div>
                <AnimatePresence>
                    {
                        showList &&
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto', transition: { type: 'spring', duration: .3 } }}
                            exit={{ opacity: 0, height: 0, transition: { duration: .2 } }}
                        >
                            <div className="py-[16px] grid grid-cols-2 gap-[18px]">
                                {
                                    actions.map((permission) =>
                                        <div
                                            key={permission}
                                            data-testid={permission.toLowerCase()}
                                            onClick={() => selectPermission(item.module, permission)}
                                            className="flex items-center px-[16px]"
                                        >
                                            {
                                                item?.action?.includes(permission) ?
                                                    <CheckboxSelected className="mr-[8px] cursor-pointer hide_tap" /> :
                                                    <CheckboxUnselected className="mr-[8px] cursor-pointer hide_tap" />
                                            }
                                            <p>{permission}</p>
                                        </div>
                                    )
                                }
                            </div>
                        </motion.div>
                    }
                </AnimatePresence>
            </div>
        </div>
    )
}

PermissionList.propTypes = {
    item: PropTypes.object,
    selectPermission: PropTypes.func
}