import axios from 'axios';
import NProgress from 'nprogress';
import { getUser } from "./token.service.js"

let isRefreshing = false;

const instance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	timeout: 60000 * 5, // 5 minutes
	crossdomain: true
});

instance.interceptors.request.use((config) => {
	config.headers["Authorization"] = `Bearer ${getUser()?.authToken}`;
	NProgress.start();
	return config;
});

instance.interceptors.response.use(
	(response) => {
		NProgress.done();
		return response;
	},
	async (error) => {
		const originalConfig = error.config;

		if (originalConfig.url !== "/login" && error.response?.data?.message === 'Session expired') {
			// Access Token is expired
			if (!originalConfig._retry && !isRefreshing) {
				originalConfig._retry = true;
				isRefreshing = true;

				// updateUser()
				// 	.then((res) => {
				// 		if (res?.code === 200) {
				// 			instance.defaults.headers.common["Authorization"] = `Bearer ${res?.data?.token}`
				// 		}
				// 		return instance(originalConfig)
				// 	})
				// 	.finally(() => isRefreshing = false)
			}
		}

		NProgress.done();
		return error;
	}
);

export default instance;
