import instance from 'services/axios-instance'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { removeSpecificKeys } from './removeSpecificKeys'

const customAsyncThunk = (thunkName, method, url) =>
    createAsyncThunk(thunkName, async (payload) => {
        try {
            const body = { ...payload?.payload }
            const pathname = payload?.pathname ? `/${payload?.pathname}` : ''
            const queryParams = payload?.params ? '?' + new URLSearchParams({ ...payload?.params }).toString() : ''
            const response = await instance[method || 'get'](`${url}${pathname}${queryParams}`, body)
            if (response?.data) return response.data ? response.data : {}
            if (response?.response?.data) return response.response.data ? response.response.data : {}
        } catch (error) {
            return error
        }
    })

const createCustomAsyncThunk = (thunkName, method, url) =>
    createAsyncThunk(thunkName, async (payload) => {
        try {
            const removeEmptyParams = removeSpecificKeys({ ...payload?.params })
            const body = { ...payload?.payload }
            const pathname = payload?.pathname ? `/${payload?.pathname}` : ''
            const queryParams = Object.keys(removeEmptyParams)?.length ? '?' + new URLSearchParams(removeEmptyParams).toString() : ''
            const response = await instance[method || 'get'](`${url}${pathname}${queryParams}`, body)
            if (response?.data) return response.data ? response.data : {}
            if (response?.response?.data) return response.response.data ? response.response.data : {}
        } catch (error) {
            return error
        }
    })

export { customAsyncThunk, createCustomAsyncThunk }
