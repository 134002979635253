import { createSlice } from '@reduxjs/toolkit'
import { getRoles } from './actions'

const initialState = {
    roles: [],
    loading: false
}

export const settings = createSlice({
    name: 'settings',
    initialState,
    extraReducers: (builder) => {
        builder
        .addCase(getRoles.fulfilled, (state, action) => {
            state.roles = action?.payload?.data
        })
    }
})

//export const {} = settings.actions
export default settings.reducer