import React from "react"
import PropTypes from "prop-types"

export default function Spinner({width}) {
    return(
        <div data-testid="spinner" className={`h-full ${width ?? "w-full"} flex justify-center items-center`}>
            <div className="spinner-icon"></div>
        </div>
    )
}

Spinner.propTypes = {
    width: PropTypes.string
}