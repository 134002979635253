/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import useMultiStep from "hooks/useMultiStep";
import { CreateRole, InviteAdmin } from "pages/Onboarding/Components";
import { ReactComponent as CheckCircleSelected } from "assets/icons/check-circle-selected.svg";
import { ReactComponent as CheckCircleUnselected } from "assets/icons/check-circle-unselected.svg";
import RequireAuth from "HOC/RequireAuth";

export default function Onboarding() {
    useEffect(() => {
        document.title = 'Onboarding - Gabbi Health'
    }, [])

    const nextStep = () => {
        next()
    }
    const previousStep = () => {
        back()
    }

    const { step, next, back } = useMultiStep([
        <CreateRole
            key={0}
            skip={nextStep}
        />,
        <InviteAdmin
            key={1}
            back={previousStep}
        />
    ])

    return (
        <RequireAuth>
            <div className="sm:flex sm:h-screen sm:overflow-y-hidden">
                <div className="sm:w-[300px] shrink-0 bg-primary_tint_50 py-[16px] sm:py-[68px] px-[24px] sm:px-[38px]">
                    <p className="sm:hidden text-18 text-neutral_black mb-[16px] font-campton_m">Onboarding</p>
                    <div className="relative flex sm:block pl-[8px] sm:pl-0">
                        <div className="relative mr-[68px] sm:mr-0">
                            <CheckCircleSelected className="mr-[8px] absolute left-[-10px]" />
                            <div className="sm:mb-[49px] pl-[20px]">
                                <p className="text-16 font-campton_m text-neutral_black mb-[4px]">Create Roles</p>
                                <p className="hidden sm:block text-14 font-campton_r text-neutral_body leading-[21px]">Lorem ipsum dolor sit amet consectetur molesti</p>
                            </div>
                        </div>
                        <div
                            className={
                                `absolute left-[130px] sm:left-0 top-[11px] sm:top-[30px] border-t sm:border-l ${step.key === '0' ? 'border-dashed' : ''}
                        w-[40px] sm:w-0 sm:h-[80px] border-l-orange_300 border-t-orange_300`
                            }
                        ></div>
                        <div className="flex relative">
                            {
                                step.key === '0' ?
                                    <CheckCircleUnselected className="mr-[8px] absolute left-[-10px]" /> :
                                    <CheckCircleSelected className="mr-[8px] absolute left-[-10px]" />
                            }
                            <div className="pl-[20px]">
                                <p className="text-16 font-campton_m text-neutral_black mb-[4px]">Invite Admin</p>
                                <p className="hidden sm:block  text-14 font-campton_r text-neutral_body leading-[21px]">Lorem ipsum dolor sit amet consectetur molesti</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-[24px] lg:px-[111px] py-[24px] sm:py-[68px] h-full w-full overflow-y-auto">
                    {step}
                </div>
            </div>
        </RequireAuth>
    )
}