import React from "react"
import PropTypes from "prop-types"
import { motion } from "framer-motion";

export default function Modal({ children, closeModal, className, animation }) {
    return (
        <div>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: .5 } }}
                exit={{ opacity: 0 }}
                className="absolute top-0 left-0 w-screen h-screen bg-[#0F2E383D] flex justify-center items-center p-[16px]"
            >
            </motion.div>
            <div
                className={
                    `flex justify-center items-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-screen h-screen p-[16px] ${className}`
                }
            >
                <div>
                    {
                        closeModal &&
                        <div
                            data-testid="close-modal"
                            className="flex justify-end mb-[16px]"
                            onClick={closeModal}
                        >
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="cursor-pointer hide_tap">
                                <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="white" />
                                <path d="M20.8535 20.146C20.9 20.1925 20.9368 20.2476 20.962 20.3083C20.9871 20.369 21.0001 20.4341 21.0001 20.4998C21.0001 20.5655 20.9871 20.6305 20.962 20.6912C20.9368 20.7519 20.9 20.8071 20.8535 20.8535C20.8071 20.9 20.7519 20.9368 20.6912 20.962C20.6305 20.9871 20.5655 21.0001 20.4998 21.0001C20.4341 21.0001 20.369 20.9871 20.3083 20.962C20.2476 20.9368 20.1925 20.9 20.146 20.8535L15.9998 16.7067L11.8535 20.8535C11.7597 20.9474 11.6325 21.0001 11.4998 21.0001C11.3671 21.0001 11.2399 20.9474 11.146 20.8535C11.0522 20.7597 10.9995 20.6325 10.9995 20.4998C10.9995 20.3671 11.0522 20.2399 11.146 20.146L15.2929 15.9998L11.146 11.8535C11.0522 11.7597 10.9995 11.6325 10.9995 11.4998C10.9995 11.3671 11.0522 11.2399 11.146 11.146C11.2399 11.0522 11.3671 10.9995 11.4998 10.9995C11.6325 10.9995 11.7597 11.0522 11.8535 11.146L15.9998 15.2929L20.146 11.146C20.2399 11.0522 20.3671 10.9995 20.4998 10.9995C20.6325 10.9995 20.7597 11.0522 20.8535 11.146C20.9474 11.2399 21.0001 11.3671 21.0001 11.4998C21.0001 11.6325 20.9474 11.7597 20.8535 11.8535L16.7067 15.9998L20.8535 20.146Z" fill="#748181" />
                                <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#ECEEEE" />
                            </svg>
                        </div>
                    }
                    <motion.div
                        initial={animation?.initial || { y: 750 }}
                        animate={animation?.animate || { y: 0, transition: { duration: .3 } }}
                        //exit={animation?.exit || { y: 750 }}
                        className="flex"
                    >
                        {children}
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

Modal.propTypes = {
    caption: PropTypes.string,
    children: PropTypes.element,
    closeModal: PropTypes.func,
    className: PropTypes.string,
    animation: PropTypes.object
}